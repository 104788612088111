import { createAsync, redirect } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { isServer } from 'solid-js/web';
import { gql, getApiClient } from '../../../graphql';
import { revalidate } from '../../../graphql/cache';
import type { Track } from '@troon/analytics';

const query = gql(`
	mutation logout {
		logout {
			success
		}
	}
`);

export const logout =
	(trackEvent: Track, redirectUrl: string = '/') =>
	async () => {
		trackEvent('logOutRequest');
		try {
			const res = await getApiClient().mutation(query, {});
			if (res.data && !res.error) {
				trackEvent('logOutSuccess');
				await revalidate();
				if (isServer || redirectUrl === window?.location.pathname) {
					redirect(redirectUrl, { revalidate: undefined });
				}
			} else {
				trackEvent('logOutFailure');
			}
			return res;
		} catch (e) {
			return true;
		}
	};

export function LogoutAction() {
	const trackEvent = useTrackEvent();
	const dataFn = logout(trackEvent);
	const success = createAsync(() => dataFn());

	createEffect(() => {
		if (success()) {
			window.location.href = '/';
		}
	});

	return null;
}
